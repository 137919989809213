<template>
  <v-theme-provider dark>
    <base-section
      id="what-make-me-lose"
      class="blue-grey darken-3 pa-4 pt-14"
      space="36"
    >
      <v-container>
        <base-section-heading
          title="Deixa eu te contar 8 coisas que fazem qualquer dieta fracassar:"
          color="red darken-5"
        />

        <p class="text-justify white--text">
         Se de um lado as distrações são inevitáveis e surgem de toda a parte (dentro e fora da sua mente), nossos níveis de motivação, persistência e comprometimento tendem a cair quando não for desenvolvido um conjunto de ações estrategicamente pensadas que nos leve na direção do objetivo definido, gerando padrões de comportamento prejudiciais e estados emocionais que podem fazer sua dieta fracassar:
        </p>

        <v-row>
          <v-col
            v-for="card in cards"
            :key="card.title"
            cols="12"
            md="3"
          >
            <base-info-card
              v-bind="card"
              color="red darken-5"
            />
          </v-col>
        </v-row>
        <base-section-footer
          title="Mas relaxa! Aqui não tem nada disso não!"
          color="red darken-5"
        />
        <base-btn-plans dark-img />
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionWhatMakeMeLose',

    data: () => ({
      cards: [
        {
          title: 'Não comer o que gosta',
          subtitle: '',
          text: '',
          callout: '1',
        },
        {
          title: 'Usar suplementos demais',
          subtitle: '',
          text: '',
          callout: '2',
        },
        {
          title: 'Alimentação muito restrita',
          subtitle: '',
          text: '',
          callout: '3',
        },
        {
          title: 'Comida cara e difícil de achar',
          subtitle: '',
          text: '',
          callout: '4',
        },
        {
          title: 'Ignorar emoções que fazem comer ou não',
          subtitle: '',
          text: '',
          callout: '5',
        },
        {
          title: 'Refeições em horários que você não gosta',
          subtitle: '',
          text: '',
          callout: '6',
        },
        {
          title: 'Passar fome ou vontade',
          subtitle: '',
          text: '',
          callout: '7',
        },
        {
          title: 'Não poder comer coisas fora da dieta',
          subtitle: '',
          text: '',
          callout: '8',
        },
      ],
    }),
  }
</script>
